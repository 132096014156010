import { Alert, Position, Pulsar } from "evergreen-ui"
import { Link } from "react-router-dom"

export const EmailStatus = () => {
    const isEmail=localStorage.getItem("emailExist")==="true"
    return (
        <>
        {!isEmail &&
        <Alert
        intent="warning"
        title={<>Please update your email id..! {<Link to="/changemail">Click Here </Link>}</>}
        position="fixed"
        zIndex={10}
        bottom="0px"
        width="100%"

      />
        }
    </>
    )
}

